
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { IFilterType } from '../../helpers/filters'

@Component({
  name: 'ModelDetailsFilterListItem'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly detail!: IFilterType

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get filterDetail () {
    return this.$queryArray('filter')
  }

  get filterIncludeDetail () {
    return this.$queryArray('filter_include')
  }

  get filterExcludeDetail () {
    return this.$queryArray('filter_exclude')
  }

  get opacity () {
    return (this.filterDetail.length || this.filterIncludeDetail.length || this.filterExcludeDetail.length) && !this.filtered && !this.included && !this.excluded ? 0.5 : 1
  }

  get icon () {
    return this.detail.icon
  }

  get filtered () {
    return this.filterDetail.includes(this.detail.id)
  }

  get included () {
    return this.filterIncludeDetail.includes(this.detail.id)
  }

  get excluded () {
    return this.filterExcludeDetail.includes(this.detail.id)
  }

  toggleDetailFilter () {
    if (this.included) {
      this.$replaceQuery({
        filter_exclude: this.$removeQueryArray(this.detail.id),
        filter_include: this.$removeQueryArray(this.detail.id)
      })
    } else {
      this.$replaceQuery({
        filter_exclude: this.$removeQueryArray(this.detail.id),
        filter_include: this.$unionQueryArray(this.detail.id)
      })

      analytics.modelDetailFilterInclude.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelObjectDetail: this.detail.id.replace('has-', ''),
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  toggleDetailExclude () {
    if (this.excluded) {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.detail.id),
        filter_exclude: this.$removeQueryArray(this.detail.id),
        filter_include: this.$removeQueryArray(this.detail.id)
      })
    } else {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.detail.id),
        filter_exclude: this.$unionQueryArray(this.detail.id),
        filter_include: this.$removeQueryArray(this.detail.id)
      })

      analytics.modelDetailFilterExclude.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelObjectDetail: this.detail.id.replace('has-', ''),
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }
}
