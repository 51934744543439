
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import TechnologyFilter from '@/modules/catalog/components/technology/filter/index.vue'
import * as catalogAnalytics from '@/modules/catalog/helpers/analytics'
import DiagramFilter from '@/modules/diagram/components/diagram-filter/index.vue'
import * as diagramAnalytics from '@/modules/diagram/helpers/analytics'
import { LandscapeModule } from '@/modules/landscape/store'
import DetailsFilter from '@/modules/model/components/details-filter/index.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import TagFilter from '@/modules/tag/components/filter/index.vue'
import * as tagAnalytics from '@/modules/tag/helpers/analytics'
import TeamFilter from '@/modules/team/components/filter/index.vue'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'
import StatusFilter from './status-filter/index.vue'
import TypeFilter from './type-filter/index.vue'

@Component({
  components: {
    DetailsFilter,
    DiagramFilter,
    Menu,
    StatusFilter,
    TagFilter,
    TeamFilter,
    TechnologyFilter,
    TypeFilter
  },
  name: 'FilterMenu'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  type: string | null = null

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get types () {
    return [
      {
        click: () => {
          this.type = 'technology'

          catalogAnalytics.catalogTechnologyFilterMenu.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        },
        icon: '$custom-duotone-code',
        id: 'technology',
        title: 'Technology'
      },
      {
        click: () => {
          this.type = 'status'

          analytics.modelStatusFilterMenu.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        },
        icon: '$fas-spinner',
        id: 'status',
        title: 'Status'
      },
      {
        click: () => {
          this.type = 'ownership'
        },
        icon: '$custom-duotone-users',
        id: 'ownership',
        title: 'Ownership'
      },
      {
        click: () => {
          this.type = 'tags'

          tagAnalytics.tagFilterMenu.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        },
        icon: '$custom-duotone-tags',
        id: 'tags',
        title: 'Tags'
      },
      {
        click: () => {
          this.type = 'type'

          analytics.modelTypeFilterMenu.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        },
        icon: '$fas-building',
        id: 'type',
        title: 'Type'
      },
      {
        click: () => {
          this.type = 'diagram'

          diagramAnalytics.diagramFilterMenu.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        },
        icon: '$fas-project-diagram',
        id: 'diagram',
        title: 'Diagram'
      },
      {
        click: () => {
          this.type = 'details'

          analytics.modelDetailsFilterMenu.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        },
        icon: '$fas-list',
        id: 'details',
        title: 'Details'
      }
    ]
  }

  open () {
    this.type = null
  }

  opened () {
    analytics.modelFilterMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }
}
